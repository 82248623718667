<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        VuePerfectScrollbar,
    },
    props: {
        isMenuOpened: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {

        }
    },
    computed: {
        // ...authComputed,
    },

    methods: {

        toggleMenu() {
            this.$parent.toggleMenu()
        },
        toggleRightSidebar() {
            // this.$parent.toggleRightSidebar();
            // document.body.classList.toggle('right-bar-enabled');           
            this.$root.$emit('openRightSideBar');
        },
    },
}
</script>

<template>
<!-- Topbar Start -->
<div class="navbar navbar-expand flex-column flex-md-row navbar-custom bg-primary">    
    <div class="container-fluid">
        <!-- LOGO -->
        <a href="/" class="navbar-brand mr-0 mr-md-2 logo" :class="{ open: !isMenuOpened }">
            <span class="logo-lg">
                <img src="@assets/images/logo.png" alt height="36" />
            </span>
            <span class="logo-sm">
                <img src="@assets/images/logo.png" alt height="36" />
            </span>
        </a>

        <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
            <li class>
                <button class="button-menu-mobile open-left" :class="{ open: isMenuOpened }" @click="toggleMenu">
                    <feather type="menu" class="menu-icon align-middle"></feather>
                    <feather type="x" class="close-icon"></feather>
                </button>
            </li>
            <!-- <li id="setting-tooltip" class="dropdown notification-list" title="Settings">
                <a href="javascript:void(0);" class="nav-link right-bar-toggle" @click="toggleRightSidebar">
                    <h2 class="text-white">Hello</h2>
                </a>
                <b-tooltip target="setting-tooltip" placement="left">Configure</b-tooltip>
            </li> -->
        </ul>

        <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">

        </ul>
    </div>
</div>
<!-- end Topbar -->
</template>

<style lang="scss">
.noti-scroll {
    height: 220px;
}

.ps>.ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
    width: 6px !important;
}

.button-menu-mobile {
    outline: none !important;
}
</style>
