<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import LocationList from './locationList.vue'
import {
    locationData
} from '@state/api/location'

export default {
    components: {
        VuePerfectScrollbar,
        LocationList
    },
    data() {
        return {
            config: {
                handler: this.handleRightBarClick,
                middleware: this.middleware,
                events: ['click'],
            },
            locations: [],
            layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
        }
    },
    computed: {
        // ...authComputed,
    },
    created() {

    },
    mounted() {
        this.$root.$on('openRightSideBar', async () => {
            await this.hideOrShowLocations();
        })
    },
    methods: {
        // ...layoutMethods,
        async hideOrShowLocations() {
            document.body.classList.toggle('right-bar-enabled');
            this.locations = await locationData.searchLocations(0, '');
        },
        hide() {
            this.$parent.toggleRightSidebar()
        },
        async handleRightBarClick(e, el) {
            if (e.target && e.target.classList.contains('rightbar-overlay'))
                this.$parent.hideRightSidebar()
        },
        middleware(event, el) {
            return !event.target.classList.contains('toggle-right')
        },
        changeLayout(layout) {
            this.changeLeftSidebarTheme({
                leftSidebarTheme: 'default'
            })
            this.changeLayoutWidth({
                layoutWidth: 'default'
            })
            this.changeLayoutType({
                layoutType: layout
            })
            this.hide()
        },
        changeTheme(theme) {
            this.changeLayoutType({
                layoutType: 'vertical'
            })
            this.changeLeftSidebarTheme({
                leftSidebarTheme: theme
            })
            this.hide()
        },
        changeType(condensed) {
            this.changeLayoutType({
                layoutType: 'vertical'
            })
            this.changeLeftSidebarType({
                leftSidebarType: condensed
            })
            this.hide()
        },
        changeWidth(boxed) {
            this.changeLayoutType({
                layoutType: 'vertical'
            })
            this.changeLayoutWidth({
                layoutWidth: boxed
            })
            this.hide()
        },
    },
}
</script>

<template>
<div>
    <div v-click-outside="config" class="right-bar">
        <div class="rightbar-title">
            <a href="javascript:void(0);" class="right-bar-toggle float-right" @click="hide">
                <feather type="x-circle"></feather>
            </a>
            <h5 class="m-0">Select Location</h5>
        </div>
        <div class="slimscroll-menu p-3">
            <VuePerfectScrollbar style="height: 100%">
                <div v-for="location of locations" :key="location.locationId">
                    <location-list :location="location" :name="location.locationName" :text="location.companyName" />
                </div>
            </VuePerfectScrollbar>
        </div>
    </div>
    <div class="rightbar-overlay"></div>
</div>
</template>
