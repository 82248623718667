<script>
import {
    mapActions
} from 'vuex';

export default {
    props: {
        name: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        location: {
            type: Object,
            default: null
        }
    },
    methods: {
        ...mapActions(['setLoggedInLocation']),
        setLocation() {
            this.setLoggedInLocation(this.location);
            document.body.classList.toggle('right-bar-enabled');
        }
    }
}
</script>
<template>
<div>
    <div class="media mt-1 border-top pt-3">
        <feather type="map-pin" class="avatar avatar-xs cursor rounded align-middle mr-2"></feather>
        <div class="media-body" @click="setLocation">
            <h6 class="mt-1 mb-0 font-size-15">{{ name }}</h6>
            <h6 class="text-muted font-weight-normal mt-1 mb-3">{{ text }}</h6>
        </div>
    </div>
</div>
</template>
