import { axiosInstance } from '../../axios'

const GETsearchLocation = `api/Location/SearchLocation`;
const GETsearchLocationByCompany = `api/Location/SearchLocation`;
const GETSearchByKey = `api/Location/SearchByKey`;
const POSTSave = "api/Location/SaveByCompany";
const DELETEDeleteLocation = "api/Location/DeleteLocation/";

const searchLocationByCompany = async function (companyId, search) {
  try {
      if (!search) {
        search = ''
      }
      const response = await axiosInstance.coreAPIInstance.get(`${GETsearchLocationByCompany}/${companyId}/search/${search}`);
      let data = parseList(response);
      return data;
  } catch (error) {
      return [];
  }
}

const searchLocations = async function (search) {
    try {
        if (search === undefined) {
          search = ''
        }
        const response = await axiosInstance.coreAPIInstance.get(GETsearchLocation + "?search=" + search);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
}

const searchByKey = async function (locationId) {
  try {
      const response = await axiosInstance.coreAPIInstance.get(GETSearchByKey + "?locationId=" + locationId);
      return response.data;
  } catch (error) {
      return {};
  }
}

const save = async function(location) {
  try {
    const response = await axiosInstance.coreAPIInstance.post(POSTSave, location);
    return response.data;
  } catch (error) {
    return null;
  }
};

const deleteLocation = async function (id) {
  try {
      const response = await axiosInstance.coreAPIInstance.delete(DELETEDeleteLocation + id);
      return response.data;
  } catch (error) {
    return null;
  }
}

const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);   

    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== 'object') {
      list = [];
    }
    return list;
  };
  

export const locationData = {
 searchLocations,
 searchLocationByCompany,
 save,
 searchByKey,
 deleteLocation
}