<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Appmenu from './app-menu'

export default {
    components: {
        VuePerfectScrollbar,
        Appmenu
    },
    props: {
        isCondensed: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true,
            default: () => ({}),
        }
    },
    data() {
        return {
            settings: {
                minScrollbarLength: 60,
            },
        }
    },
    computed: {},
    methods: {},
    watch: {
        theme: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                switch (newVal) {
                    case 'dark':
                        document.body.classList.add('left-side-menu-dark')
                        document.body.classList.remove('left-side-menu-condensed')
                        document.body.classList.remove('boxed-layout')
                        break
                    default:
                        document.body.classList.remove('left-side-menu-dark')
                        break
                }
            }
        },
        type: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                switch (newVal) {
                    case 'condensed':
                        document.body.classList.add('left-side-menu-condensed')
                        document.body.classList.remove('left-side-menu-dark')
                        document.body.classList.remove('boxed-layout')
                        break
                    default:
                        document.body.classList.remove('left-side-menu-condensed')
                        break
                }
            }
        },
        width: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                switch (newVal) {
                    case 'boxed':
                        document.body.classList.add('left-side-menu-condensed')
                        document.body.classList.remove('left-side-menu-dark')
                        document.body.classList.add('boxed-layout')
                        break
                    default:
                        document.body.classList.remove('left-side-menu-condensed')
                        document.body.classList.remove('boxed-layout')
                        break
                }
            }
        },
    },
}
</script>

<template>
<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu">
    <div class="media user-profile mt-2 mb-2 p-0 list-unstyled">

        <b-nav-item-dropdown id="bell-notification" left variant="white" class="notification-list align-self-center p-2" menu-class="dropdown-lg">
            <template v-slot:button-content>

                <img src="@assets/images/users/avatar-1.png" class="float-left avatar-sm rounded-circle mr-2" />
                <img src="@assets/images/users/avatar-1.png" class="avatar-xs rounded-circle mr-2" />

                <div class="media-body float-left">
                    <h6 class="pro-user-name mb-0">{{ user.name }}</h6>
                </div>
            </template>

            <b-dropdown-text class="bg-light notify-item notify-all border-top cursor">
                <h5 class="m-0 font-size-16">
                    <a href="/logout" class="text-dark">
                        Logout
                    </a>
                </h5>
            </b-dropdown-text>
        </b-nav-item-dropdown>

    </div>

    <div class="sidebar-content">
        <VuePerfectScrollbar v-if="!isCondensed" v-once class="slimscroll-menu" :settings="settings">
            <div id="sidebar-menu">
                <Appmenu />
            </div>
        </VuePerfectScrollbar>
        <div v-else id="sidebar-menu">
            <Appmenu />
        </div>
    </div>
    <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->
</template>

<style lang="scss">
.slimscroll-menu {
    height: 100%;
}

.ps>.ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
    width: 6px !important;
}
</style>
