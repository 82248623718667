const appMenu = [
    {
        path: '/home',
        name: 'Home',
        header: 'Start Here',
        icon: 'home',
    },
    {
        path: '/Event/CreateEvent',
        name: 'Create Event',
        header: 'Events',
        icon: 'plus',
    },    
    {
        path: '/Event/AllActiveEvents',
        name: 'Active Events',
        icon: 'plus',
    },
    // {
    //     path: '/Event/PendingEvents',
    //     name: 'Pending Approvals',
    //     icon: 'plus',
    // },
    {
        path: '/Enterprise/Procedures',
        name: 'Procedures',
        header: 'Enterprise',
        icon: 'plus',
    },
    {
        path: '/Customers',
        name: 'Customers',
        header: 'Customers',
        icon: 'map-pin',
    },
    {
        path: '/Locations',
        name: 'Locations',
        icon: 'map-pin',
    }  
]

export const menuItems = {
    appMenu
  };