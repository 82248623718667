<template>
    <!-- Footer Start -->
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    Version: {{ currentVersion }} 2024 &copy; Powered by tawcs Pty Ltd. All Rights Reserved.
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</template>

<script>
import {
    version
} from '@/package.json';

export default {
    data() {
        return {
            currentVersion: version,
        }
    },
}
</script>
